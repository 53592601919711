import React, { useContext, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  Row,
  Tab,
  Nav,
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap"
import "aos/dist/aos.css"
import AOS from "aos"
import cdots1 from "../images/dots/faqs/1.png"
import cdots2 from "../images/dots/faqs/2.png"
import cdots3 from "../images/dots/faqs/3.png"

// eslint-disable-next-line react/prop-types
function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <div
      type="button"
      className={isCurrentEventKey ? "show" : ""}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  )
}

const Faqs = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  return (
    <Layout pageName="Faqs">
      <SEO title="FAQs" />
      <div className="bg-sec py-5">
        <Container>
          <Row>
            <div className="col-lg-4 jtf-cont pr-0 posrel">
              <img
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
                src={cdots1}
                className="faq-patch"
              />
              <img
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
                src={cdots2}
                className="faq-patch"
              />
              <img
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                src={cdots3}
                className="faq-patch"
              />
              <div>
                <p className="ed-title text-center text-lg-left">FAQs</p>
                <p className="wn-title mt-0 text-center text-lg-left">
                  Everything you need to know about Teach For India
                </p>
              </div>
            </div>
            <div className="col-lg-8 pl-lg-5">
              <p className="fi-txt">Dear 2022 Fellows,</p>
              <p className="fi-txt">
                Our mission is all the more relevant today with the pandemic
                pushing those from under-resourced communities further into poor
                living conditions, and we wouldn’t start this journey with
                anyone else but changemakers like you who want to tackle
                educational inequity and set out on their own leadership
                development journey. Things are changing rapidly and the last
                few months have been unprecedented, but we’re prepared. We are
                also moulding ourselves to be agile and flexible for the future.
                We will continue to update this section of the website as our
                plans develop.
              </p>
              <p className="fi-txt">
                The last 21 months were a novel challenge for all of us but with
                the support of our Staff, Fellows and Alumni, we were able to
                make our Fellowship virtual and bring alive the concept of
                blended learning. With the generous support of our donors and
                well-wishers, we were also able to procure thousands of gadgets
                so that our children don’t stop learning.
              </p>
              <p className="fi-txt">
                This year, we will continue the blended model of asynchronous
                and synchronous learning, but as state COVID advisories permit,
                we will return to classrooms full time. If you have any doubts
                and concerns, we are here to offer as much clarity as we can. We
                are adding a new set of frequently asked questions – which we
                will keep updating. If you have a more urgent question, please
                email us at{" "}
                <a
                  href="mailto:fellowship2022@teachforindia.org"
                  className="fi-link"
                >
                  fellowship2022@teachforindia.org.
                </a>
              </p>
              <p className="fi-txt">The Teach For India Team</p>
            </div>
          </Row>
        </Container>
      </div>
      <div className="d-none d-lg-block">
        <Container className="py-lg-5">
          <Tab.Container id="left-tabs-example" defaultActiveKey="second">
            <Row className="tabContaier">
              <div className="col-lg-12 px-0">
                <Nav className="faqTabNav">
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      Fellowship <br />
                      FAQs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      Application
                      <br />
                      FAQs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fifth">
                      Teach For India
                      <br />
                      FAQs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth">
                      Donation
                      <br />
                      FAQs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      Covid-19 <br />
                      FAQs
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <div className="col-lg-12 px-lg-0">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Accordion className="faq-acc">
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="0">
                            <div>
                              <p className="fi-txt">
                                Will Teach For India be offering support during
                                Selection and Matriculation?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              We have a dedicated team to support you at your
                              Assessment Center and Matriculation spaces. As we,
                              and you, are operating remotely, for now, a lot of
                              our support will be virtual (e.g. video
                              conferencing, phone calls and emails). We are also
                              committed to bring to you the best versions of our
                              otherwise in-person spaces such as Assessment
                              Centres and Matriculation events through webinars,
                              1:1 virtual interactions and phone calls.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="1">
                            <div>
                              <p className="fi-txt">
                                What will be the structure of Institute this
                                year?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Keeping in mind the post covid health advisories
                              and academic calendars for the respective
                              placement cities, this year our Institute will be
                              a regional, blended one. This means there will be
                              residential in-person training at a regional hub
                              followed by blended (in-person +virtual) training
                              in your own placement cities. The Institute
                              training weeks will be designed keeping the
                              residential and blended format in mind. For
                              instance, Week 1-3: Residential Institute in one
                              of these regions (Bangalore, Pune, Delhi). Weeks
                              4-8: Blended Institute in your placement cities
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="2">
                            <div>
                              <p className="fi-txt">
                                Will we get paid during the Institute?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Yes, you will. However, the HRA component will be
                              paid only when you move to your placement city.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="3">
                            <div>
                              <p className="fi-txt">
                                Can we live at home during Institute if we
                                belong to the placement city the Institute is
                                in? / Will we be getting housing during the
                                Institute?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              No. During the first three weeks, you have to be
                              at one of our regional residences. The residential
                              training weeks will require all Fellows to stay at
                              the regional residential training hub for the
                              given duration of time.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="4">
                            <div>
                              <p className="fi-txt">
                                On what basis will we be placed in schools?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Fellows are placed in schools keeping in mind the
                              Student, school and city needs. Fellows are also
                              asked about their preference in terms of grades
                              and subject at Selection which will be taken into
                              account. Additionally, a Fellow’s basic
                              proficiency in different teaching subjects
                              understood during Institute also helps with school
                              placements decision making. You will receive your
                              school placement in the second/third week of
                              Institute before you move into your placement
                              city.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="5">
                            <div>
                              <p className="fi-txt">
                                What should I put as the last date for my
                                current job?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              By the end of February 2021, the dates for
                              Institute will be shared with Fellow accepts which
                              will give you ample time to plan your last date in
                              your current job.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="6">
                            <div>
                              <p className="fi-txt">
                                How will I get on-ground learning experience to
                                become an educator?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="6">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              We will ensure that you get the most holistic
                              experience whilst at Institute. There, we will
                              expose you to summer school, teaching and all
                              other forms of training that our Fellows go
                              through every year. The first three weeks of
                              Institute will be an immersion into the program
                              and learning the basics of classroom teaching and
                              culture. The next five weeks after that, you will
                              be placed in your school where you&apos;ll teach
                              and practice and also be learning.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="7">
                            <div>
                              <p className="fi-txt">
                                What is the latest date I can join Institute if
                                I graduate post-June 15th?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="7">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Irrespective of your final placement, you will
                              have to join the Delhi Institute to cover your
                              training.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="8">
                            <div>
                              <p className="fi-txt">
                                My parents are worried about my safety while
                                working in classrooms, can someone at Teach For
                                India speak to them?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="8">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              In such unpredictable situations, it’s natural for
                              parents and guardians to have concerns about your
                              well-being. Someone from our team will be glad to
                              speak to them and clarify any doubts - please
                              write to fellowship2021@teachforindia.org and we
                              will take from there.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="9">
                            <div>
                              <p className="fi-txt">
                                Can I defer my place in the Fellowship due to
                                COVID-19?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="9">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              We want to reassure you that despite the ongoing
                              safety concerns, schools still need teachers. For
                              our 2022 Fellowship, we will continue to match you
                              with a school and support you in your preparation
                              to teach in a classroom. Every decision we make
                              over the coming months will be made thinking about
                              our Fellows, our partner schools and the
                              communities we serve. It’s crucial to us that
                              every change we make in our processes is
                              thoughtful and empathetic towards the new cohort.
                              Therefore, our deferral policy remains the same-
                              we do not accept deferrals.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="10">
                            <div>
                              <p className="fi-txt">
                                Will Teach For India take any safety measures at
                                Institute to ensure our well-being?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="10">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India does take care of basic safety
                              measures throughout the duration of Institute for
                              all Fellows and Staff present. Keeping in mind the
                              post-COVID state guidelines and health advisories,
                              Teach For India is working on additional safety
                              measures required to ensure well being of our
                              Fellows and Staff during Institute training.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="11">
                            <div>
                              <p className="fi-txt">
                                Will Teach For India support us in placements
                                post the Fellowship if the economy/ job markets
                                continue to remain weak?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="11">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              This information is also currently available on
                              our website. We have an External Career Fair with
                              employees from across the country and an Internal
                              Career Fair for roles at Teach For India. You will
                              have access to both as a Fellow.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Accordion className="faq-acc">
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="0">
                            <div>
                              <p className="fi-txt">
                                Why should I join Teach For India?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India offers you the chance to make a
                              real difference in tackling one of India’s most
                              pressing challenges — educational inequity. By
                              presenting you with one of the most difficult
                              challenges of your life, the Fellowship will help
                              you develop leadership skills that are critical in
                              today’s dynamic global work environment. The
                              Fellowship Program gives you an opportunity to
                              become a part of the solution and develop as a
                              change agent in society.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="1">
                            <div>
                              <p className="fi-txt">Who are we looking for?</p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              In our Fellows, we are looking for leaders to join
                              a movement towards educational equity. For that,
                              aside from passion and commitment to the movement,
                              we look for leadership potential that can develop
                              through the two-year Fellowship.
                            </p>
                            <p className="fi-txt text-left">
                              Our Fellows must possess skills such as critical
                              thinking, problem-solving, grit and have a high
                              bar of excellence for themselves and others. They
                              must demonstrate interpersonal skills through
                              their interactions with various stakeholders,
                              along with academic excellence.
                            </p>
                            <p className="fi-txt text-left">
                              We look for diversity in our cohort. Our Fellows
                              come from across the world representing over 300
                              college campuses and companies, with varied life
                              experiences, expertise and professional
                              experiences. Our cohorts include college
                              graduates, working professionals, and
                              entrepreneurs.
                            </p>
                            <p className="fi-txt text-left">
                              In order to be eligible to apply to the Fellowship
                              program, you must be an Indian Citizen or an
                              Overseas Citizen of India, you must have graduated
                              with a minimum of a Bachelor&apos;s degree by
                              June/July 2022, and this must be your first
                              application to the 2022 cohort of the Teach For
                              India Fellowship.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="2">
                            <div>
                              <p className="fi-txt">
                                What does the Teach For India Fellowship entail?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              The Teach For India Fellowship is a 2-year,
                              full-time, paid commitment to teach in an
                              under-resourced classroom in a government or
                              low-income private school. In the first year,
                              Fellows will refine their teaching skills and will
                              start to establish respect and trust among various
                              stakeholders within the school community and
                              amongst their Students. This will allow Fellows to
                              achieve significant academic gains for Students
                              and to implement their community/school project in
                              their second year. Furthermore, Teach For India is
                              looking for leaders who are willing to commit to
                              long-term change and have the perseverance to work
                              hard in challenging situations. In this context,
                              the two-year commitment is but a small step
                              towards long-term reform and is necessary to make
                              the impact we wish to have on student achievement.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="3">
                            <div>
                              <p className="fi-txt">
                                How many Fellows does Teach For India currently
                                have?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India currently has approximately 970
                              Fellows, teaching across 7 cities and 3800 Alumni.
                              We will soon be starting operations in our 8th
                              site, Kolkata, adding to our existing presence in
                              the cities of Ahmedabad, Bengaluru, Chennai.
                              Delhi, Hyderabad, Mumbai and Pune. Teach For India
                              Fellows are teaching approximately 32,000 children
                              in these cities.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="4">
                            <div>
                              <p className="fi-txt">
                                What are the profiles of Teach For India
                                Fellows?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India Fellows are a diverse group of
                              people, brought together by their past
                              achievements and their passion to eliminate
                              educational inequity. They come from diverse
                              backgrounds of colleges such as IIT, IIM, St.
                              Stephen’s College, St. Xavier’s College, Christ
                              University, Presidency College and companies such
                              as Accenture, McKinsey, Mahindra & Mahindra, IBM,
                              Ernst & Young, Schlumberger, etc. By joining the
                              Fellowship, you will get the opportunity to work
                              with some of the brightest and most enterprising
                              minds of the country.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="5">
                            <div>
                              <p className="fi-txt">
                                How does Teach For India&apos;s approach to
                                teaching differ from traditional approaches?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              At Teach For India, we believe that teaching is
                              leadership. For our Fellows and Students to be
                              leaders, we focus on building skills and mindsets
                              through our Leadership Development Journey. The
                              LDJ takes Fellows through 8 milestones in which
                              they explore where their classroom is on the
                              Student Vision Scale, where they are on the Fellow
                              Commitments Scale, what competencies they need to
                              work on and which opportunities they can avail of
                              to work on those. This is a cycle to build
                              reflective practice throughout the journey. The
                              Student Vision Scale defines the kind of Academic
                              Achievement, Values and Mindsets and Exposure and
                              Access our Students need to have path-changing
                              learning and make them Student leaders. The Fellow
                              Commitments Scale defines the three commitments,
                              to Personal Transformation, to Collective Action
                              and to Education Equity that all of us in the
                              movement need to be leaders who will go on to
                              eliminate educational inequity in the country.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="6">
                            <div>
                              <p className="fi-txt">
                                Which standard will I be teaching and what will
                                be the medium of instruction?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="6">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India Fellows are placed in 1st to 10th
                              standard classrooms where they teach all major
                              subjects — including English, Mathematics, Social
                              Studies and Science with the exception of regional
                              languages. Although the mother tongue is helpful
                              in interacting within our communities, it is not a
                              necessary prerequisite, as our Fellows teach in
                              English medium classrooms.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="7">
                            <div>
                              <p className="fi-txt">
                                Where will I be teaching?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="7">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India Fellows will be placed in
                              low-income schools in Ahmedabad, Bengaluru,
                              Chennai, Delhi, Hyderabad, Kolkata, Mumbai and
                              Pune. All Fellows will be required to reside in
                              one of these cities during the period of the
                              Fellowship. If you are unable to do so, please
                              note that we will expand into more cities in the
                              upcoming years, and hope to be in 10 cities and
                              their surrounding rural areas within the next 5
                              years.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="8">
                            <div>
                              <p className="fi-txt">
                                Will I be trained adequately before I start
                                teaching?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="8">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India will work relentlessly to ensure
                              that all Fellows have the knowledge and skills
                              necessary to lead their Students towards
                              achievement. The Teach For India Fellowship begins
                              with a five-week residential training program.
                              Here, Fellows are exposed to curriculum, lesson
                              planning, and classroom facilitation and student
                              assessments among other modules, sessions and keys
                              to successful teaching. Additionally, each Fellow
                              will have a mentor in the form of a Program
                              Manager whose primary responsibility is to guide
                              Fellows to become effective teachers and leaders.
                              Additionally, Teach For India will also provide
                              ongoing technical training from in-house trainers.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="9">
                            <div>
                              <p className="fi-txt">
                                How much will I be paid during the Fellowship?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="9">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India Fellows will be paid a salary of
                              Rs. 20,412 per month. If required to relocate from
                              their home city, Fellows will be given a housing
                              allowance ranging from Rs. 5,300 to Rs. 10,000,
                              depending on the city in which they will stay. In
                              addition, Fellows will receive an allowance for
                              school supplies.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="10">
                            <div>
                              <p className="fi-txt">
                                How will the Teach For India experience help me
                                grow professionally?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="10">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India strongly believes that excellent
                              teachers employ the same skills as excellent
                              leaders in any field. Spending two years in the
                              classroom will help Fellows develop leadership
                              skills such as organisation, communication,
                              problem-solving in an unstructured environment,
                              people management and resourcefulness that are
                              valuable in any sector. Teach For India will
                              select Fellows who have demonstrated excellence in
                              the past and will work with them throughout the
                              two-year Fellowship to ensure that they take
                              advantage of the experience to become
                              extraordinary future leaders.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="11">
                            <div>
                              <p className="fi-txt">
                                What options will be open to me after the
                                Fellowship?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="11">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Our 3800+ Alumni are now spread all over the
                              country and abroad in jobs ranging from consulting
                              to education startups; and as students in top
                              universities such as Harvard, Cornell, Chicago
                              Booth, Columbia and Tata Institute of Social
                              Sciences. Teach For India supports its Fellows to
                              pursue their individual career interests by
                              training and mentoring them and striving to build
                              relationships with top schools, universities,
                              NGOs, and corporations throughout the country.
                              Teach For India has already established close ties
                              with HDFC, Godrej Industries, Harvard Business
                              School, IIM Bangalore, ISB, McKinsey & Company,
                              Tata Consultancy Services, Columbia Law School,
                              Ernst & Young and the Thermax Group, among many
                              others.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="12">
                            <div>
                              <p className="fi-txt">
                                If I can&apos;t apply to the Fellowship now, can
                                I apply at a later date?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="12">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India will accept applications for the
                              Fellowship every year. If you are unable to
                              consider joining the Fellowship at this time, we
                              welcome you to consider applying for the
                              Fellowship in the subsequent years. However,
                              before making the decision, please write to us{" "}
                              <a
                                href="mailto:apply@teachforindia.org"
                                className="fi-link"
                              >
                                apply@teachforindia.org
                              </a>{" "}
                              and we would be glad to address your concerns and
                              see if we can make it work for the upcoming year.
                              For questions on the application and selection
                              process, please see Application FAQs above. If you
                              have additional queries, write to us at{" "}
                              <a
                                href="mailto:apply@teachforindia.org"
                                className="fi-link"
                              >
                                apply@teachforindia.org.
                              </a>
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Accordion className="faq-acc">
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="0">
                            <div>
                              <p className="fi-txt">
                                How can I apply to the Teach For India
                                Fellowship?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Visit{" "}
                              <a
                                href="https://apply.teachforindia.org"
                                className="fin-link bold"
                              >
                                apply.teachforindia.org
                              </a>{" "}
                              to apply to the Fellowship.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="1">
                            <div>
                              <p className="fi-txt">
                                How can I save the Application Form?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              The online application is designed to let you
                              complete various sections in your own time before
                              you submit the final version. The application form
                              is automatically saved as you enter information.
                              The Fellowship Selection team will review your
                              application only after you submit the form along
                              with the online test. They will not look at your
                              application while you are still working on it.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="2">
                            <div>
                              <p className="fi-txt">
                                In spite of registering, why have I not received
                                an email yet?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              We are sure our system is completely compatible
                              with email services such as Gmail and Yahoo.
                              However, there are certain email services such as
                              Hotmail that have a highly restrictive email
                              filter and hence, it could be the case that the
                              email we sent did not get delivered to you. We
                              request you to register using a Gmail or Yahoo
                              account.
                            </p>
                            <p className="fi-txt text-left">
                              Also, please check your spam/junk folder to ensure
                              the email has not gotten delivered there. If you
                              have still not received the email, please email{" "}
                              <a
                                href="mailto:apply@teachforindia.org"
                                className="fi-link"
                              >
                                apply@teachforindia.org
                              </a>{" "}
                              and we will get back to you within 48 hours.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="3">
                            <div>
                              <p className="fi-txt">
                                What is the eligibility criteria to apply to the
                                2022 Teach For India Fellowship Program?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body>
                            <div className=" text-left">
                              <p className="fi-txt">
                                (a) Minimum Qualification: You must have
                                completed all coursework required for your
                                undergraduate degree (Bachelor’s degree
                                equivalent) from an accredited college or
                                university by the last week of May 2022. Your
                                degree transcripts (graduate/postgraduate
                                certificates) will be reviewed before an
                                admission decision can be taken. Applicants must
                                also pass any coursework indicated in their
                                transcripts at the time of the interview.
                              </p>
                              <p className="fi-txt">
                                (b) Nationality: You should be either an Indian
                                citizen or a foreign national of Indian origin.
                                Foreign citizens who are not of Indian origin
                                are not eligible to apply.
                              </p>
                              <p className="fi-txt">
                                Note to Foreign nationals of Indian origin: For
                                more information on the OCI card, please go to
                                the website of the Ministry of Home Affairs. To
                                apply for the OCI card, visit the Online OCI
                                Registration Form. We recommend applying for the
                                OCI card as soon as you have made the decision
                                to join the Fellowship, as it takes
                                approximately 1-3 months to receive the card.
                              </p>
                              <p className="fi-txt">
                                (c) Commitment and Location: The Fellowship is a
                                2-year, full-time, paid commitment to teaching
                                and living in one of our placement cities
                                (Ahmedabad, Bengaluru, Chennai, Delhi,
                                Hyderabad, Kolkata, Mumbai, Pune) starting
                                May/June 2022. Fellows will be full-time staff
                                members of the schools in which they are placed
                                and will work stipulated school hours six days a
                                week as well as attend training and support
                                sessions as per city requirements.
                              </p>
                              <p className="fi-txt">
                                (d) English Fluency: You should be fluent in the
                                English language, as Teach For India Fellows
                                will be placed in English-medium classrooms and
                                will be expected to teach in and attend training
                                sessions conducted in English.
                              </p>
                              <p className="fi-txt">
                                (e) Age Limit: There is no age limit to apply
                                for Teach For India Fellowship.
                              </p>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="4">
                            <div>
                              <p className="fi-txt">
                                On the application form, how do I enter my
                                academic outcomes?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                          <Card.Body>
                            <div className=" text-left">
                              <p className="fi-txt">
                                Based on the educational qualification, you can
                                select one of three or four grading systems on
                                the application form, that is, Percentage, GPA,
                                CGPA or Grades. Check which grading system is
                                most relevant to you for each educational
                                qualification, and then fill in the required
                                information.
                              </p>
                              <p className="fi-txt">
                                For example, if your institution uses Cumulative
                                Average (CGPA) as its measure for academic
                                achievement and your cumulative average is
                                8.0/10.0, select CGPA as the grading system, and
                                then fill in “8.0” as the Score and “10.0” as
                                the Base. If your institution does not calculate
                                any of these measures for you, you will have to
                                calculate the average of the final grades of all
                                of your coursework and fill in the relevant
                                information. If you are currently in your final
                                year at college and hence do not have your final
                                year graduation scores, enter an average of your
                                scores from all your previous years at college.
                                Note that if you are accepted onto the
                                Fellowship program, we will need documentation
                                in the form of mark sheets or transcripts for
                                your educational qualifications, and other
                                documentation for professional experiences.
                              </p>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="5">
                            <div>
                              <p className="fi-txt">
                                Why are the essays important?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                          <Card.Body>
                            <div className=" text-left">
                              <p className="fi-txt">
                                The essays are your chance to tell us about
                                yourself and your interest in Teach For India.
                                As they are extended answers with no upper word
                                limit, you will be able to use them as an
                                opportunity to demonstrate to us in detail why
                                you are a strong candidate for the Fellowship.
                                Therefore, spend time developing your
                                understanding of Teach For India, by reading our
                                website, watching our short videos, speaking
                                with Staff, Fellows or Alumni or reading-related
                                articles, as well as thinking about what you
                                want to tell us before writing your response.
                                Ensure that you have checked your essays for
                                spelling and grammatical errors as well as
                                ensure that you have answered each question
                                fully. We recommend that you draft your essays
                                offline before proofreading and then
                                copy-pasting them onto the application form, to
                                ensure that the essays you submit are of the
                                highest quality.
                              </p>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="6">
                            <div>
                              <p className="fi-txt">
                                When is the application deadline?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="6">
                          <Card.Body>
                            <div className=" text-left">
                              <p className="fi-txt">
                                Based on the date that the application form is
                                submitted, you will be a part of one of our
                                three application rounds. An applicant can apply
                                only once during each selection cycle. Hence, be
                                sure that you submit your best and also complete
                                the online test within 72 hours of submitting
                                the form.
                              </p>
                              <p className="fi-txt">
                                Our three application deadlines for the 2022
                                Fellowship are:
                              </p>
                              <p className="fi-txt">
                                Round 1: 19th September 2021, 11:59 PM IST
                              </p>
                              <p className="fi-txt">
                                Round 2: 28th November 2021, 11:59 PM IST
                              </p>
                              <p className="fi-txt">
                                Round 3: 6th February 2022, 11:59 PM IST
                              </p>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="7">
                            <div>
                              <p className="fi-txt">When will I hear back?</p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="7">
                          <Card.Body>
                            <div className=" text-left">
                              <p className="fi-txt">
                                Based on the date that the application form is
                                submitted, you will be a part of one of our
                                application rounds. An applicant can apply only
                                once during each selection cycle. Hence, be sure
                                that you submit your best and also complete the
                                online test within 72 hours of submitting the
                                form.
                              </p>
                              <p className="fi-txt">
                                Our application deadlines for the 2022
                                Fellowship along with the outcome dates for each
                                are:
                              </p>
                              <p className="fi-txt">Round 1:</p>
                              <p className="fi-txt">
                                Deadline Date: 19th September 2021
                              </p>
                              <p className="fi-txt">
                                Application outcome date: 1st October 2021
                              </p>
                              <p className="fi-txt">Round 2:</p>
                              <p className="fi-txt">
                                Deadline Date: 28th November 2021
                              </p>
                              <p className="fi-txt">
                                Application outcome date: 9th December 2021
                              </p>
                              <p className="fi-txt">Round 3:</p>
                              <p className="fi-txt">
                                Deadline Date: 6th February 2022
                              </p>
                              <p className="fi-txt">
                                Application outcome date: 17th February 2022
                              </p>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <Accordion className="faq-acc">
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="0">
                            <div>
                              <p className="fi-txt">
                                How long will it take for me to receive my tax
                                exemption certificate/receipt for my donation?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India will send a Section 80G Income Tax
                              Exemption receipt of your donation to the address
                              you mentioned in the donation form or to the email
                              address you provide within 2 weeks. [for Indian
                              citizens only]
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="1">
                            <div>
                              <p className="fi-txt">
                                How can I donate to Teach For India?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Donations to Teach For India can be made both
                              online and offline. You can donate online by a
                              credit card or debit card or through net banking
                              using our secure donation page. You can donate
                              offline by making a cheque in the name of “Teach
                              To Lead” along with a cover letter stating your
                              full name, address, PAN and the purpose of your
                              donation ( eg. Towards Sponsor a Fellow, Fellow
                              BTCP) and mailing it to the following address:
                            </p>
                            <p className="fi-txt">Teach For India</p>
                            <p className="fi-txt">Attn: Development Team</p>
                            <p className="fi-txt">Godrej One, 2nd Floor</p>
                            <p className="fi-txt">
                              Pirojshanagar,Eastern Express Highway,
                            </p>
                            <p className="fi-txt">Vikhroli (East),</p>
                            <p className="fi-txt">Mumbai - 400 079, India</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="2">
                            <div>
                              <p className="fi-txt">
                                Which agencies are authorised to collect
                                donations on behalf of Teach For India?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Currently, the following 3 agencies collect
                              donations on Teach For India’s behalf:
                            </p>
                            <p className="fi-txt">1. Give India</p>
                            <p className="fi-txt">2. Global Giving</p>
                            <p className="fi-txt">3. United Way</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="3">
                            <div>
                              <p className="fi-txt">
                                Will I get Tax exemptions on my donations?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Yes, Teach For India (registered as Teach To Lead)
                              has a certificate for Tax Exemption under Section
                              80G of the Income Tax Act, 1961. All donations to
                              Teach For India are 50% exempt from tax under
                              Section 80G. The tax exemption 80G is applicable
                              only for donations made by Indian citizens /
                              entities eligible for this exemption.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="4">
                            <div>
                              <p className="fi-txt">
                                I live in the United States and/or would like to
                                support Teach For India with a donation in USD.
                                What should I do?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              All donors making donations in USD should donate
                              directly to our US partner, Friends of Teach For
                              India and NOT donate on the main Teach For India
                              website. Friends of Teach For India (EIN
                              47-3725301) is based in New York City and is a
                              registered 501(c)3 organization. All donations to
                              Friends of Teach For India are tax-deductible in
                              accordance with IRS regulations.
                            </p>
                            <p className="fi-txt text-left">
                              If you would like to donate to Friends of Teach
                              For India, please visit <br />
                              <a
                                href="https://tfius.org"
                                className="fin-link bold"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://tfius.org.
                              </a>
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="5">
                            <div>
                              <p className="fi-txt">
                                Can foreign citizens donate online?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Yes, foreign nationals can donate online to Teach
                              For India. For further queries please write to{" "}
                              <a
                                href="mailto:donate@teachforindia.org"
                                className="fin-link bold"
                              >
                                donate@teachforindia.org
                              </a>
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="7">
                            <div>
                              <p className="fi-txt">
                                If a donor donates to a specific Fellow, will
                                the entire amount be directed to the Fellow for
                                classroom expenses?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="7">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Yes. The entire amount is allocated to the
                              Fellow’s Be The Change Project.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="8">
                            <div>
                              <p className="fi-txt">
                                What portion of the donation amount is deducted
                                as transaction fees?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="8">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              The payment gateway charge is about 3-5% and is
                              deducted by CCAvenue.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="9">
                            <div>
                              <p className="fi-txt">
                                What will my donation be used for?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="9">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Your donation will be used towards the Fellowship,
                              the Alumni program, the projects in the Innovation
                              Cell and/or the operation costs to fuel these
                              programs in any of the 8 cities (Ahmedabad,
                              Bangalore, Chennai, Delhi, Hyderabad, Kolkata,
                              Mumbai and Pune )that Teach For India operates in.
                              On an average Teach For India spends 13 % of funds
                              raised on administration and the remainder on
                              programmatic expenditure.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="10">
                            <div>
                              <p className="fi-txt">
                                Will I receive a fund utilization report for my
                                donation?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="10">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              All online donors receive a monthly newsletter
                              updating them on the progress of Teach For India’s
                              work. Since the donations are pooled together and
                              used for the entire program as a whole, online
                              donors do not receive a fund utilisation report.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="11">
                            <div>
                              <p className="fi-txt">
                                If I am a donor, can I visit a Teach For India
                                classroom?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="11">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              You are welcome to visit a Teach For India
                              classroom even if you are not a donor. However
                              school visits require prior permissions to be
                              taken by our Fellows. Please allow one to two
                              weeks before a visit can be scheduled. You can
                              write to{" "}
                              <a
                                href="mailto:donate@teachforindia.org"
                                className="fin-link bold"
                              >
                                donate@teachforindia.org
                              </a>{" "}
                              with your request.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="12">
                            <div>
                              <p className="fi-txt">
                                How do I contact Teach For India regarding my
                                donation?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="12">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              For more details or any further assistance please
                              email us at{" "}
                              <a
                                href="mailto:donate@teachforindia.org"
                                className="fin-link bold"
                              >
                                donate@teachforindia.org
                              </a>
                            </p>
                            <p className="fi-txt text-left">
                              You can also contact us at our Mumbai office:
                            </p>
                            <p className="fi-txt text-left">Teach For India</p>
                            <p className="fi-txt text-left">
                              Attn: Development Team
                            </p>
                            <p className="fi-txt text-left">
                              Godrej One, 2nd Floor
                            </p>
                            <p className="fi-txt text-left">
                              Pirojshanagar,Eastern Express Highway,
                            </p>
                            <p className="fi-txt text-left">Vikhroli (East),</p>
                            <p className="fi-txt text-left">
                              Mumbai - 400 079, India
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="13">
                            <div>
                              <p className="fi-txt">
                                If I have an urgent request or a problem related
                                to my donation, is there a phone number where I
                                can reach a member of the donor services team?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="13">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Yes, please contact at:{" "}
                              <a
                                href="tel:+91 22 25194283"
                                className="fin-link medium"
                              >
                                +91 22 25194283
                              </a>
                              ,{" "}
                              <a
                                href="tel:+91 25194284"
                                className="fin-link medium"
                              >
                                25194284
                              </a>{" "}
                              and ask for someone from the development team.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="14">
                            <div>
                              <p className="fi-txt">
                                If as a corporate, foundation or individual I
                                wish to partner with Teach For India and
                                contribute more, whom should I contact?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="14">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India partners with individuals,
                              foundations and corporations. To discuss how we
                              can partner with you or your organization, please
                              write to us at{" "}
                              <a
                                href="mailto:donate@teachforindia.org"
                                className="fin-link bold"
                              >
                                donate@teachforindia.org
                              </a>
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <Accordion className="faq-acc">
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="0">
                            <div>
                              <p className="fi-txt">
                                What is Teach For India’s vision and mission?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              At Teach For India, we believe leadership for
                              education is the solution. We are building a
                              movement of leaders who will eliminate educational
                              inequity in India. Our vision is that one day all
                              children will attain an excellent education.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="1">
                            <div>
                              <p className="fi-txt">
                                What is Teach For India’s impact?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Over the last decade, we’ve set up a Fellowship
                              that has proven impact for accelerating both
                              Student learning and Fellow leadership
                              development. More than 120,000 young people have
                              applied to Teach For India’s Fellowship and
                              currently, we have 970+ Fellows impacting over
                              32000 Students in 270 under-resourced schools in
                              India. We have 9100 Student Alumni. Since our
                              inception 12 years ago, we have infused the system
                              with more than 3800+ Alumni who have been running
                              their own schools and organizations, training
                              teachers, designing policy and continuing to serve
                              within classrooms, non-profits, and corporate
                              institutions. Most importantly, they’re working
                              together to collectively build a better India.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="2">
                            <div>
                              <p className="fi-txt">
                                What is Teach For India’s Alumni Movement?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India Alumni are serving more than 33
                              million children; 1 in 10 Indian children is now
                              reached by a Teach For India Alumni. More than 77%
                              of our Alumni continue to serve in the social
                              sector, a commitment that is fueled by belief.
                              More than 90% of our Alumni are convinced that
                              India’s children can receive an excellent
                              education. A recent study also tells us that our
                              community has founded more than 168 organizations.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="3">
                            <div>
                              <p className="fi-txt">
                                Who donates to Teach For India?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              Teach For India gratefully acknowledges the
                              support of the following individuals, foundations,
                              and corporations who have championed our efforts.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <ContextAwareToggle eventKey="4">
                            <div>
                              <p className="fi-txt">
                                How can one get stories from Teach For India
                                community?
                              </p>
                              <span className="acc-plus-icon">+</span>
                              <span className="acc-minus-icon">-</span>
                            </div>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                          <Card.Body>
                            <p className="fi-txt text-left">
                              You can write to Teach For India’s National
                              Communication team to get more stories of hope and
                              truth{" "}
                              <a
                                href="mailto:communication@teachforindia.org"
                                className="fin-link bold"
                              >
                                -communication@teachforindia.org
                              </a>
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Row>
          </Tab.Container>
        </Container>
      </div>

      <div className="d-block d-lg-none">
        <Container>
          <p className="wn-title borderBottomBlack">Fellowship FAQs</p>
          <Accordion className="faq-acc">
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="0">
                  <div>
                    <p className="fi-txt">Why should I join Teach For India?</p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India offers you the chance to make a real
                    difference in tackling one of India’s most pressing
                    challenges — educational inequity. By presenting you with
                    one of the most difficult challenges of your life, the
                    Fellowship will help you develop leadership skills that are
                    critical in today’s dynamic global work environment. The
                    Fellowship Program gives you an opportunity to become a part
                    of the solution and develop as a change agent in society.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="1">
                  <div>
                    <p className="fi-txt">Who are we looking for?</p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="fi-txt text-left">
                    In our Fellows, we are looking for leaders to join a
                    movement towards educational equity. For that, aside from
                    passion and commitment to the movement, we look for
                    leadership potential that can develop through the two-year
                    Fellowship.
                  </p>
                  <p className="fi-txt text-left">
                    Our Fellows must possess skills such as critical thinking,
                    problem-solving, grit and have a high bar of excellence for
                    themselves and others. They must demonstrate interpersonal
                    skills through their interactions with various stakeholders,
                    along with academic excellence.
                  </p>
                  <p className="fi-txt text-left">
                    We look for diversity in our cohort. Our Fellows come from
                    across the world representing over 300 college campuses and
                    companies, with varied life experiences, expertise and
                    professional experiences. Our cohorts include college
                    graduates, working professionals, and entrepreneurs.
                  </p>
                  <p className="fi-txt text-left">
                    In order to be eligible to apply to the Fellowship program,
                    you must be an Indian Citizen or an Overseas Citizen of
                    India, you must have graduated with a minimum of a
                    Bachelor&apos;s degree by June/July 2022, and this must be
                    your first application to the 2022 cohort of the Teach For
                    India Fellowship.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="2">
                  <div>
                    <p className="fi-txt">
                      What does the Teach For India Fellowship entail?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="fi-txt text-left">
                    The Teach For India Fellowship is a 2-year, full-time, paid
                    commitment to teach in an under-resourced classroom in a
                    government or low-income private school. In the first year,
                    Fellows will refine their teaching skills and will start to
                    establish respect and trust among various stakeholders
                    within the school community and amongst their Students. This
                    will allow Fellows to achieve significant academic gains for
                    Students and to implement their community/school project in
                    their second year. Furthermore, Teach For India is looking
                    for leaders who are willing to commit to long-term change
                    and have the perseverance to work hard in challenging
                    situations. In this context, the two-year commitment is but
                    a small step towards long-term reform and is necessary to
                    make the impact we wish to have on student achievement.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="3">
                  <div>
                    <p className="fi-txt">
                      How many Fellows does Teach For India currently have?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India currently has approximately 970 Fellows,
                    teaching across 7 cities and 3800 Alumni. We will soon be
                    starting operations in our 8th site, Kolkata, adding to our
                    existing presence in the cities of Ahmedabad, Bengaluru,
                    Chennai. Delhi, Hyderabad, Mumbai and Pune. Teach For India
                    Fellows are teaching approximately 32,000 children in these
                    cities.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="4">
                  <div>
                    <p className="fi-txt">
                      What are the profiles of Teach For India Fellows?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India Fellows are a diverse group of people,
                    brought together by their past achievements and their
                    passion to eliminate educational inequity. They come from
                    diverse backgrounds of colleges such as IIT, IIM, St.
                    Stephen’s College, St. Xavier’s College, Christ University,
                    Presidency College and companies such as Accenture,
                    McKinsey, Mahindra & Mahindra, IBM, Ernst & Young,
                    Schlumberger, etc. By joining the Fellowship, you will get
                    the opportunity to work with some of the brightest and most
                    enterprising minds of the country.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="5">
                  <div>
                    <p className="fi-txt">
                      How does Teach For India&apos;s approach to teaching
                      differ from traditional approaches?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p className="fi-txt text-left">
                    At Teach For India, we believe that teaching is leadership.
                    For our Fellows and Students to be leaders, we focus on
                    building skills and mindsets through our Leadership
                    Development Journey. The LDJ takes Fellows through 8
                    milestones in which they explore where their classroom is on
                    the Student Vision Scale, where they are on the Fellow
                    Commitments Scale, what competencies they need to work on
                    and which opportunities they can avail of to work on those.
                    This is a cycle to build reflective practice throughout the
                    journey. The Student Vision Scale defines the kind of
                    Academic Achievement, Values and Mindsets and Exposure and
                    Access our Students need to have path-changing learning and
                    make them Student leaders. The Fellow Commitments Scale
                    defines the three commitments, to Personal Transformation,
                    to Collective Action and to Education Equity that all of us
                    in the movement need to be leaders who will go on to
                    eliminate educational inequity in the country.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="6">
                  <div>
                    <p className="fi-txt">
                      Which standard will I be teaching and what will be the
                      medium of instruction?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India Fellows are placed in 1st to 10th standard
                    classrooms where they teach all major subjects — including
                    English, Mathematics, Social Studies and Science with the
                    exception of regional languages. Although the mother tongue
                    is helpful in interacting within our communities, it is not
                    a necessary prerequisite, as our Fellows teach in English
                    medium classrooms.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="7">
                  <div>
                    <p className="fi-txt">Where will I be teaching?</p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India Fellows will be placed in low-income schools
                    in Ahmedabad, Bengaluru, Chennai, Delhi, Hyderabad, Kolkata,
                    Mumbai and Pune. All Fellows will be required to reside in
                    one of these cities during the period of the Fellowship. If
                    you are unable to do so, please note that we will expand
                    into more cities in the upcoming years, and hope to be in 10
                    cities and their surrounding rural areas within the next 5
                    years.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="8">
                  <div>
                    <p className="fi-txt">
                      Will I be trained adequately before I start teaching?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India will work relentlessly to ensure that all
                    Fellows have the knowledge and skills necessary to lead
                    their Students towards achievement. The Teach For India
                    Fellowship begins with a five-week residential training
                    program. Here, Fellows are exposed to curriculum, lesson
                    planning, and classroom facilitation and student assessments
                    among other modules, sessions and keys to successful
                    teaching. Additionally, each Fellow will have a mentor in
                    the form of a Program Manager whose primary responsibility
                    is to guide Fellows to become effective teachers and
                    leaders. Additionally, Teach For India will also provide
                    ongoing technical training from in-house trainers.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="9">
                  <div>
                    <p className="fi-txt">
                      How much will I be paid during the Fellowship?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India Fellows will be paid a salary of Rs. 20,412
                    per month. If required to relocate from their home city,
                    Fellows will be given a housing allowance ranging from Rs.
                    5,300 to Rs. 10,000, depending on the city in which they
                    will stay. In addition, Fellows will receive an allowance
                    for school supplies.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="10">
                  <div>
                    <p className="fi-txt">
                      How will the Teach For India experience help me grow
                      professionally?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India strongly believes that excellent teachers
                    employ the same skills as excellent leaders in any field.
                    Spending two years in the classroom will help Fellows
                    develop leadership skills such as organisation,
                    communication, problem-solving in an unstructured
                    environment, people management and resourcefulness that are
                    valuable in any sector. Teach For India will select Fellows
                    who have demonstrated excellence in the past and will work
                    with them throughout the two-year Fellowship to ensure that
                    they take advantage of the experience to become
                    extraordinary future leaders.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="11">
                  <div>
                    <p className="fi-txt">
                      What options will be open to me after the Fellowship?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Our 3800+ Alumni are now spread all over the country and
                    abroad in jobs ranging from consulting to education
                    startups; and as students in top universities such as
                    Harvard, Cornell, Chicago Booth, Columbia and Tata Institute
                    of Social Sciences. Teach For India supports its Fellows to
                    pursue their individual career interests by training and
                    mentoring them and striving to build relationships with top
                    schools, universities, NGOs, and corporations throughout the
                    country. Teach For India has already established close ties
                    with HDFC, Godrej Industries, Harvard Business School, IIM
                    Bangalore, ISB, McKinsey & Company, Tata Consultancy
                    Services, Columbia Law School, Ernst & Young and the Thermax
                    Group, among many others.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="12">
                  <div>
                    <p className="fi-txt">
                      If I can&apos;t apply to the Fellowship now, can I apply
                      at a later date?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="12">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India will accept applications for the Fellowship
                    every year. If you are unable to consider joining the
                    Fellowship at this time, we welcome you to consider applying
                    for the Fellowship in the subsequent years. However, before
                    making the decision, please write to us{" "}
                    <a
                      href="mailto:apply@teachforindia.org"
                      className="fi-link"
                    >
                      apply@teachforindia.org
                    </a>{" "}
                    and we would be glad to address your concerns and see if we
                    can make it work for the upcoming year. For questions on the
                    application and selection process, please see Application
                    FAQs above. If you have additional queries, write to us at{" "}
                    <a
                      href="mailto:apply@teachforindia.org"
                      className="fi-link"
                    >
                      apply@teachforindia.org.
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
        <Container>
          <p className="wn-title borderBottomBlack">Application FAQs</p>
          <Accordion className="faq-acc">
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="0">
                  <div>
                    <p className="fi-txt">
                      How can I apply to the Teach For India Fellowship?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Visit{" "}
                    <a
                      href="https://apply.teachforindia.org"
                      className="fin-link bold"
                    >
                      apply.teachforindia.org
                    </a>{" "}
                    to apply to the Fellowship.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="1">
                  <div>
                    <p className="fi-txt">
                      How can I save the Application Form?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="fi-txt text-left">
                    The online application is designed to let you complete
                    various sections in your own time before you submit the
                    final version. The application form is automatically saved
                    as you enter information. The Fellowship Selection team will
                    review your application only after you submit the form along
                    with the online test. They will not look at your application
                    while you are still working on it.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="2">
                  <div>
                    <p className="fi-txt">
                      In spite of registering, why have I not received an email
                      yet?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="fi-txt text-left">
                    We are sure our system is completely compatible with email
                    services such as Gmail and Yahoo. However, there are certain
                    email services such as Hotmail that have a highly
                    restrictive email filter and hence, it could be the case
                    that the email we sent did not get delivered to you. We
                    request you to register using a Gmail or Yahoo account.
                  </p>
                  <p className="fi-txt text-left">
                    Also, please check your spam/junk folder to ensure the email
                    has not gotten delivered there. If you have still not
                    received the email, please email{" "}
                    <a
                      href="mailto:apply@teachforindia.org"
                      className="fi-link"
                    >
                      apply@teachforindia.org
                    </a>{" "}
                    and we will get back to you within 48 hours.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="3">
                  <div>
                    <p className="fi-txt">
                      What is the eligibility criteria to apply to the 2022
                      Teach For India Fellowship Program?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <div className=" text-left">
                    <p className="fi-txt">
                      (a) Minimum Qualification: You must have completed all
                      coursework required for your undergraduate degree
                      (Bachelor’s degree equivalent) from an accredited college
                      or university by the last week of May 2022. Your degree
                      transcripts (graduate/postgraduate certificates) will be
                      reviewed before an admission decision can be taken.
                      Applicants must also pass any coursework indicated in
                      their transcripts at the time of the interview.
                    </p>
                    <p className="fi-txt">
                      (b) Nationality: You should be either an Indian citizen or
                      a foreign national of Indian origin. Foreign citizens who
                      are not of Indian origin are not eligible to apply.
                    </p>
                    <p className="fi-txt">
                      Note to Foreign nationals of Indian origin: For more
                      information on the OCI card, please go to the website of
                      the Ministry of Home Affairs. To apply for the OCI card,
                      visit the Online OCI Registration Form. We recommend
                      applying for the OCI card as soon as you have made the
                      decision to join the Fellowship, as it takes approximately
                      1-3 months to receive the card.
                    </p>
                    <p className="fi-txt">
                      (c) Commitment and Location: The Fellowship is a 2-year,
                      full-time, paid commitment to teaching and living in one
                      of our placement cities (Ahmedabad, Bengaluru, Chennai,
                      Delhi, Hyderabad, Kolkata, Mumbai, Pune) starting May/June
                      2022. Fellows will be full-time staff members of the
                      schools in which they are placed and will work stipulated
                      school hours six days a week as well as attend training
                      and support sessions as per city requirements.
                    </p>
                    <p className="fi-txt">
                      (d) English Fluency: You should be fluent in the English
                      language, as Teach For India Fellows will be placed in
                      English-medium classrooms and will be expected to teach in
                      and attend training sessions conducted in English.
                    </p>
                    <p className="fi-txt">
                      (e) Age Limit: There is no age limit to apply for Teach
                      For India Fellowship.
                    </p>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="4">
                  <div>
                    <p className="fi-txt">
                      On the application form, how do I enter my academic
                      outcomes?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <div className=" text-left">
                    <p className="fi-txt">
                      Based on the educational qualification, you can select one
                      of three or four grading systems on the application form,
                      that is, Percentage, GPA, CGPA or Grades. Check which
                      grading system is most relevant to you for each
                      educational qualification, and then fill in the required
                      information.
                    </p>
                    <p className="fi-txt">
                      For example, if your institution uses Cumulative Average
                      (CGPA) as its measure for academic achievement and your
                      cumulative average is 8.0/10.0, select CGPA as the grading
                      system, and then fill in “8.0” as the Score and “10.0” as
                      the Base. If your institution does not calculate any of
                      these measures for you, you will have to calculate the
                      average of the final grades of all of your coursework and
                      fill in the relevant information. If you are currently in
                      your final year at college and hence do not have your
                      final year graduation scores, enter an average of your
                      scores from all your previous years at college. Note that
                      if you are accepted onto the Fellowship program, we will
                      need documentation in the form of mark sheets or
                      transcripts for your educational qualifications, and other
                      documentation for professional experiences.
                    </p>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="5">
                  <div>
                    <p className="fi-txt">Why are the essays important?</p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <div className=" text-left">
                    <p className="fi-txt">
                      The essays are your chance to tell us about yourself and
                      your interest in Teach For India. As they are extended
                      answers with no upper word limit, you will be able to use
                      them as an opportunity to demonstrate to us in detail why
                      you are a strong candidate for the Fellowship. Therefore,
                      spend time developing your understanding of Teach For
                      India, by reading our website, watching our short videos,
                      speaking with Staff, Fellows or Alumni or reading-related
                      articles, as well as thinking about what you want to tell
                      us before writing your response. Ensure that you have
                      checked your essays for spelling and grammatical errors as
                      well as ensure that you have answered each question fully.
                      We recommend that you draft your essays offline before
                      proofreading and then copy-pasting them onto the
                      application form, to ensure that the essays you submit are
                      of the highest quality.
                    </p>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="6">
                  <div>
                    <p className="fi-txt">When is the application deadline?</p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <div className=" text-left">
                    <p className="fi-txt">
                      Based on the date that the application form is submitted,
                      you will be a part of one of our three application rounds.
                      An applicant can apply only once during each selection
                      cycle. Hence, be sure that you submit your best and also
                      complete the online test within 72 hours of submitting the
                      form.
                    </p>
                    <p className="fi-txt">
                      Our three application deadlines for the 2022 Fellowship
                      are:
                    </p>
                    <p className="fi-txt">
                      Round 1: 19th September 2021, 11:59 PM IST
                    </p>
                    <p className="fi-txt">
                      Round 2: 28th November 2021, 11:59 PM IST
                    </p>
                    <p className="fi-txt">
                      Round 3: 6th February 2022, 11:59 PM IST
                    </p>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="7">
                  <div>
                    <p className="fi-txt">When will I hear back?</p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <div className=" text-left">
                    <p className="fi-txt">
                      Based on the date that the application form is submitted,
                      you will be a part of one of our application rounds. An
                      applicant can apply only once during each selection cycle.
                      Hence, be sure that you submit your best and also complete
                      the online test within 72 hours of submitting the form.
                    </p>
                    <p className="fi-txt">
                      Our application deadlines for the 2022 Fellowship along
                      with the outcome dates for each are:
                    </p>
                    <p className="fi-txt">Round 1:</p>
                    <p className="fi-txt">Deadline Date: 19th September 2021</p>
                    <p className="fi-txt">
                      Application outcome date: 1st October 2021
                    </p>
                    <p className="fi-txt">Round 2:</p>
                    <p className="fi-txt">Deadline Date: 28th November 2021</p>
                    <p className="fi-txt">
                      Application outcome date: 9th December 2021
                    </p>
                    <p className="fi-txt">Round 3:</p>
                    <p className="fi-txt">Deadline Date: 6th February 2022</p>
                    <p className="fi-txt">
                      Application outcome date: 17th February 2022
                    </p>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
        <Container>
          <p className="wn-title borderBottomBlack">Teach For India FAQs</p>
          <Accordion className="faq-acc">
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="0">
                  <div>
                    <p className="fi-txt">
                      What is Teach For India’s vision and mission?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p className="fi-txt text-left">
                    At Teach For India, we believe leadership for education is
                    the solution. We are building a movement of leaders who will
                    eliminate educational inequity in India. Our vision is that
                    one day all children will attain an excellent education.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="1">
                  <div>
                    <p className="fi-txt">What is Teach For India’s impact?</p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Over the last decade, we’ve set up a Fellowship that has
                    proven impact for accelerating both Student learning and
                    Fellow leadership development. More than 120,000 young
                    people have applied to Teach For India’s Fellowship and
                    currently, we have 970+ Fellows impacting over 32000
                    Students in 270 under-resourced schools in India. We have
                    9100 Student Alumni. Since our inception 12 years ago, we
                    have infused the system with more than 3800+ Alumni who have
                    been running their own schools and organizations, training
                    teachers, designing policy and continuing to serve within
                    classrooms, non-profits, and corporate institutions. Most
                    importantly, they’re working together to collectively build
                    a better India.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="2">
                  <div>
                    <p className="fi-txt">
                      What is Teach For India’s Alumni Movement?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India Alumni are serving more than 33 million
                    children; 1 in 10 Indian children is now reached by a Teach
                    For India Alumni. More than 77% of our Alumni continue to
                    serve in the social sector, a commitment that is fueled by
                    belief. More than 90% of our Alumni are convinced that
                    India’s children can receive an excellent education. A
                    recent study also tells us that our community has founded
                    more than 168 organizations.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="3">
                  <div>
                    <p className="fi-txt">Who donates to Teach For India?</p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India gratefully acknowledges the support of the
                    following individuals, foundations, and corporations who
                    have championed our efforts.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="4">
                  <div>
                    <p className="fi-txt">
                      How can one get stories from Teach For India community?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="fi-txt text-left">
                    You can write to Teach For India’s National Communication
                    team to get more stories of hope and truth{" "}
                    <a
                      href="mailto:communication@teachforindia.org"
                      className="fin-link bold"
                    >
                      -communication@teachforindia.org
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>

        <Container>
          <p className="wn-title borderBottomBlack">Donation FAQs</p>
          <Accordion className="faq-acc">
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="0">
                  <div>
                    <p className="fi-txt">
                      How long will it take for me to receive my tax exemption
                      certificate/receipt for my donation?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India will send a Section 80G Income Tax Exemption
                    receipt of your donation to the address you mentioned in the
                    donation form or to the email address you provide within 2
                    weeks. [for Indian citizens only]
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="1">
                  <div>
                    <p className="fi-txt">
                      How can I donate to Teach For India?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Donations to Teach For India can be made both online and
                    offline. You can donate online by a credit card or debit
                    card or through net banking using our secure donation page.
                    You can donate offline by making a cheque in the name of
                    “Teach To Lead” along with a cover letter stating your full
                    name, address, PAN and the purpose of your donation ( eg.
                    Towards Sponsor a Fellow, Fellow BTCP) and mailing it to the
                    following address:
                  </p>
                  <p className="fi-txt">Teach For India</p>
                  <p className="fi-txt">Attn: Development Team</p>
                  <p className="fi-txt">Godrej One, 2nd Floor</p>
                  <p className="fi-txt">
                    Pirojshanagar,Eastern Express Highway,
                  </p>
                  <p className="fi-txt">Vikhroli (East),</p>
                  <p className="fi-txt">Mumbai - 400 079, India</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="2">
                  <div>
                    <p className="fi-txt">
                      Which agencies are authorised to collect donations on
                      behalf of Teach For India?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Currently, the following 3 agencies collect donations on
                    Teach For India’s behalf:
                  </p>
                  <p className="fi-txt">1. Give India</p>
                  <p className="fi-txt">2. Global Giving</p>
                  <p className="fi-txt">3. United Way</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="3">
                  <div>
                    <p className="fi-txt">
                      Will I get Tax exemptions on my donations?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Yes, Teach For India (registered as Teach To Lead) has a
                    certificate for Tax Exemption under Section 80G of the
                    Income Tax Act, 1961. All donations to Teach For India are
                    50% exempt from tax under Section 80G. The tax exemption 80G
                    is applicable only for donations made by Indian citizens /
                    entities eligible for this exemption.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="4">
                  <div>
                    <p className="fi-txt">
                      I live in the United States and/or would like to support
                      Teach For India with a donation in USD. What should I do?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="fi-txt text-left">
                    All donors making donations in USD should donate directly to
                    our US partner, Friends of Teach For India and NOT donate on
                    the main Teach For India website. Friends of Teach For India
                    (EIN 47-3725301) is based in New York City and is a
                    registered 501(c)3 organization. All donations to Friends of
                    Teach For India are tax-deductible in accordance with IRS
                    regulations.
                  </p>
                  <p className="fi-txt text-left">
                    If you would like to donate to Friends of Teach For India,
                    please visit <br />
                    <a
                      href="https://tfius.org"
                      className="fin-link bold"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://tfius.org
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="5">
                  <div>
                    <p className="fi-txt">
                      Can foreign citizens donate online?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Yes, foreign nationals can donate online to Teach For India.
                    For further queries please write to{" "}
                    <a
                      href="mailto:donate@teachforindia.org"
                      className="fin-link bold"
                    >
                      donate@teachforindia.org
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="7">
                  <div>
                    <p className="fi-txt">
                      If a donor donates to a specific Fellow, will the entire
                      amount be directed to the Fellow for classroom expenses?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Yes. The entire amount is allocated to the Fellow’s Be The
                    Change Project.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="8">
                  <div>
                    <p className="fi-txt">
                      What portion of the donation amount is deducted as
                      transaction fees?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <p className="fi-txt text-left">
                    The payment gateway charge is about 3-5% and is deducted by
                    CCAvenue.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="9">
                  <div>
                    <p className="fi-txt">What will my donation be used for?</p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Your donation will be used towards the Fellowship, the
                    Alumni program, the projects in the Innovation Cell and/or
                    the operation costs to fuel these programs in any of the 8
                    cities (Ahmedabad, Bangalore, Chennai, Delhi, Hyderabad,
                    Kolkata, Mumbai and Pune )that Teach For India operates in.
                    On an average Teach For India spends 13 % of funds raised on
                    administration and the remainder on programmatic
                    expenditure.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="10">
                  <div>
                    <p className="fi-txt">
                      Will I receive a fund utilization report for my donation?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <p className="fi-txt text-left">
                    All online donors receive a monthly newsletter updating them
                    on the progress of Teach For India’s work. Since the
                    donations are pooled together and used for the entire
                    program as a whole, online donors do not receive a fund
                    utilisation report.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="11">
                  <div>
                    <p className="fi-txt">
                      If I am a donor, can I visit a Teach For India classroom?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <p className="fi-txt text-left">
                    You are welcome to visit a Teach For India classroom even if
                    you are not a donor. However school visits require prior
                    permissions to be taken by our Fellows. Please allow one to
                    two weeks before a visit can be scheduled. You can write to{" "}
                    <a
                      href="mailto:donate@teachforindia.org"
                      className="fin-link bold"
                    >
                      donate@teachforindia.org
                    </a>{" "}
                    with your request.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="12">
                  <div>
                    <p className="fi-txt">
                      How do I contact Teach For India regarding my donation?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="12">
                <Card.Body>
                  <p className="fi-txt text-left">
                    For more details or any further assistance please email us
                    at{" "}
                    <a
                      href="mailto:donate@teachforindia.org"
                      className="fin-link bold"
                    >
                      donate@teachforindia.org
                    </a>
                  </p>
                  <p className="fi-txt text-left">
                    You can also contact us at our Mumbai office:
                  </p>
                  <p className="fi-txt text-left">Teach For India</p>
                  <p className="fi-txt text-left">Attn: Development Team</p>
                  <p className="fi-txt text-left">Godrej One, 2nd Floor</p>
                  <p className="fi-txt text-left">
                    Pirojshanagar,Eastern Express Highway,
                  </p>
                  <p className="fi-txt text-left">Vikhroli (East),</p>
                  <p className="fi-txt text-left">Mumbai - 400 079, India</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="13">
                  <div>
                    <p className="fi-txt">
                      If I have an urgent request or a problem related to my
                      donation, is there a phone number where I can reach a
                      member of the donor services team?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="13">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Yes, please contact at:{" "}
                    <a href="tel:+91 22 25194283" className="fin-link medium">
                      +91 22 25194283
                    </a>
                    ,{" "}
                    <a href="tel:+91 25194284" className="fin-link medium">
                      25194284
                    </a>{" "}
                    and ask for someone from the development team.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="14">
                  <div>
                    <p className="fi-txt">
                      If as a corporate, foundation or individual I wish to
                      partner with Teach For India and contribute more, whom
                      should I contact?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="14">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India partners with individuals, foundations and
                    corporations. To discuss how we can partner with you or your
                    organization, please write to us at{" "}
                    <a
                      href="mailto:donate@teachforindia.org"
                      className="fin-link bold"
                    >
                      donate@teachforindia.org
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>

        <Container className="pb-5">
          <p className="wn-title borderBottomBlack">Covid-19 FAQs</p>
          <Accordion className="faq-acc">
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="0">
                  <div>
                    <p className="fi-txt">
                      Will Teach For India be offering support during Selection
                      and Matriculation?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p className="fi-txt text-left">
                    We have a dedicated team to support you at your Assessment
                    Center and Matriculation spaces. As we, and you, are
                    operating remotely, for now, a lot of our support will be
                    virtual (e.g. video conferencing, phone calls and emails).
                    We are also committed to bring to you the best versions of
                    our otherwise in-person spaces such as Assessment Centres
                    and Matriculation events through webinars, 1:1 virtual
                    interactions and phone calls.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="1">
                  <div>
                    <p className="fi-txt">
                      What will be the structure of Institute this year?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Keeping in mind the post covid health advisories and
                    academic calendars for the respective placement cities, this
                    year our Institute will be a regional, blended one. This
                    means there will be residential in-person training at a
                    regional hub followed by blended (in-person +virtual)
                    training in your own placement cities. The Institute
                    training weeks will be designed keeping the residential and
                    blended format in mind. For instance, Week 1-3: Residential
                    Institute in one of these regions (Bangalore, Pune, Delhi).
                    Weeks 4-8: Blended Institute in your placement cities
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="2">
                  <div>
                    <p className="fi-txt">
                      Will we get paid during the Institute?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Yes, you will. However, the HRA component will be paid only
                    when you move to your placement city.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="3">
                  <div>
                    <p className="fi-txt">
                      Can we live at home during Institute if we belong to the
                      placement city the Institute is in? / Will we be getting
                      housing during the Institute?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="fi-txt text-left">
                    No. During the first three weeks, you have to be at one of
                    our regional residences. The residential training weeks will
                    require all Fellows to stay at the regional residential
                    training hub for the given duration of time.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="4">
                  <div>
                    <p className="fi-txt">
                      On what basis will we be placed in schools?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Fellows are placed in schools keeping in mind the Student,
                    school and city needs. Fellows are also asked about their
                    preference in terms of grades and subject at Selection which
                    will be taken into account. Additionally, a Fellow’s basic
                    proficiency in different teaching subjects understood during
                    Institute also helps with school placements decision making.
                    You will receive your school placement in the second/third
                    week of Institute before you move into your placement city.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="5">
                  <div>
                    <p className="fi-txt">
                      What should I put as the last date for my current job?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p className="fi-txt text-left">
                    By the end of February 2021, the dates for Institute will be
                    shared with Fellow accepts which will give you ample time to
                    plan your last date in your current job.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="6">
                  <div>
                    <p className="fi-txt">
                      How will I get on-ground learning experience to become an
                      educator?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p className="fi-txt text-left">
                    We will ensure that you get the most holistic experience
                    whilst at Institute. There, we will expose you to summer
                    school, teaching and all other forms of training that our
                    Fellows go through every year. The first three weeks of
                    Institute will be an immersion into the program and learning
                    the basics of classroom teaching and culture. The next five
                    weeks after that, you will be placed in your school where
                    you&apos;ll teach and practice and also be learning.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="7">
                  <div>
                    <p className="fi-txt">
                      What is the latest date I can join Institute if I graduate
                      post-June 15th?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Irrespective of your final placement, you will have to join
                    the Delhi Institute to cover your training.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="8">
                  <div>
                    <p className="fi-txt">
                      My parents are worried about my safety while working in
                      classrooms, can someone at Teach For India speak to them?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <p className="fi-txt text-left">
                    In such unpredictable situations, it’s natural for parents
                    and guardians to have concerns about your well-being.
                    Someone from our team will be glad to speak to them and
                    clarify any doubts - please write to
                    fellowship2021@teachforindia.org and we will take from
                    there.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="9">
                  <div>
                    <p className="fi-txt">
                      Can I defer my place in the Fellowship due to COVID-19?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <p className="fi-txt text-left">
                    We want to reassure you that despite the ongoing safety
                    concerns, schools still need teachers. For our 2022
                    Fellowship, we will continue to match you with a school and
                    support you in your preparation to teach in a classroom.
                    Every decision we make over the coming months will be made
                    thinking about our Fellows, our partner schools and the
                    communities we serve. It’s crucial to us that every change
                    we make in our processes is thoughtful and empathetic
                    towards the new cohort. Therefore, our deferral policy
                    remains the same- we do not accept deferrals.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="10">
                  <div>
                    <p className="fi-txt">
                      Will Teach For India take any safety measures at Institute
                      to ensure our well-being?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <p className="fi-txt text-left">
                    Teach For India does take care of basic safety measures
                    throughout the duration of Institute for all Fellows and
                    Staff present. Keeping in mind the post-COVID state
                    guidelines and health advisories, Teach For India is working
                    on additional safety measures required to ensure well being
                    of our Fellows and Staff during Institute training.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="11">
                  <div>
                    <p className="fi-txt">
                      Will Teach For India support us in placements post the
                      Fellowship if the economy/ job markets continue to remain
                      weak?
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <p className="fi-txt text-left">
                    This information is also currently available on our website.
                    We have an External Career Fair with employees from across
                    the country and an Internal Career Fair for roles at Teach
                    For India. You will have access to both as a Fellow.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </div>
    </Layout>
  )
}

export default Faqs
